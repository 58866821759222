import { Application, Controller } from '@hotwired/stimulus';
import SlimSelect from 'slim-select';

class SelectController extends Controller {
  static values = {
    pages: Array,
  };

  static targets = ['dropdown', 'finish'];

  connect() {
    // eslint-disable-next-line no-new
    new SlimSelect({
      data: this.pagesValue,
      select: this.dropdownTarget,
      showSearch: false,
      placeholder: 'Select from the list...',
    });
  }

  finish() {
    document.location = this.dropdownTarget.value;
  }
}

const application = Application.start();
application.register('select', SelectController);
